.about {
  padding: 2rem;
  width: 100%;
  color: #444;
  text-align: justify;
  margin: auto;
  text-align: center;
  padding-top: 12vh;
  padding-bottom: 5%;
  clip-path: polygon(0 28%, 100% 0, 100% 100%, 0% 100%);
}

@media only screen and (max-width: 400px) {
  .about {
    padding-top: 16vh;
  }
}
