@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-size: 1rem;
  display: flex;
}
main.main-wrapper {
  display: flex;
  flex-direction: column;
}

#root {
  width: 100%;
}
