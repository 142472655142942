@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-size: 1rem;
  display: flex;
}
main.main-wrapper {
  display: flex;
  flex-direction: column;
}

#root {
  width: 100%;
}

.mynavbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2.5rem;
  position: absolute;
  z-index: 1;
  width: 100%;
  color: #fff;
}

.mynavbar-sticky {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 1px 1px 1px #222;
  animation: moveDown 0.5s ease-in-out;
  color: #444;
  z-index: 50;
  opacity: 0.9;
}

.navbar--logo-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar--logo {
  width: 10rem;
  height: 10rem;
  margin-right: 0.5rem;
  animation: rotate 0.7s ease-in-out 0.5s;
}

.navbar--logo-sticky {
  width: 5rem;
  height: 5rem;
  margin-right: 0.5rem;
  animation: rotate 0.7s ease-in-out 0.5s;
}

.navbar--link {
  display: flex;
  list-style: none;
}
.navbar--link-item a {
  color: #fff;
  text-decoration: none;
}
.navbar--link-item a::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #fff;
  transition: width 0.3s;
}
.navbar--link-item a:hover {
  color: lightgrey;
  text-decoration: none;
}
.navbar--link-item a:hover::after {
  width: 100%;
  /* transition: width .3s; */
}

.navbar--link-item-sticky a {
  color: #000;
}
.navbar--link-item-sticky a:hover {
  color: lightgrey;
  text-decoration: none;
}
.navbar--link-item {
  margin: 0.4rem 1rem 0 0;
  padding: 0 0.3rem;
  cursor: pointer;
  font-size: 1.5vw;
  text-transform: capitalize;
}

.navbar--link-item-sticky {
  margin: 0.4rem 1rem 0 0;
  padding: 0 0.3rem;
  cursor: pointer;
  font-size: 1rem;
  text-transform: capitalize;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0rem);
  }
}

@media only screen and (max-width: 1100px) {
  .navbar--link-item {
    margin: 0.4rem 1rem 0 0;
    padding: 0 0.3rem;
    cursor: pointer;
    font-size: 1.9vw;
    text-transform: capitalize;
  }
}

@media only screen and (max-width: 700px) {
  .mynavbar--logo-holder {
    margin: 0 auto;
  }
  .navbar--link {
    margin: 0 auto;
  }
  .navbar--link-item {
    margin: 0.4rem 1rem 0 0;
    padding: 0 0.3rem;
    cursor: pointer;
    font-size: 2.5vw;
    text-transform: capitalize;
  }
}
@media only screen and (max-width: 500px) {
  .navbar--link-item {
    margin: 0.4rem 1rem 0 0;
    padding: 0 0.3rem;
    cursor: pointer;
    font-size: 4vw;
    text-transform: capitalize;
  }
}

@media only screen and (max-width: 350px) {
  .navbar--link-item {
    margin: 0.4rem 1rem 0 0;
    padding: 0 0.3rem;
    cursor: pointer;
    font-size: 3vw;
    text-transform: capitalize;
  }
  .navbar--link-item-sticky {
    margin: 0.4rem 1rem 0 0;
    padding: 0 0.3rem;
    cursor: pointer;
    font-size: 3vw;
    text-transform: capitalize;
  }
}

.about {
  padding: 2rem;
  width: 100%;
  color: #444;
  text-align: justify;
  margin: auto;
  text-align: center;
  padding-top: 12vh;
  padding-bottom: 5%;
  -webkit-clip-path: polygon(0 28%, 100% 0, 100% 100%, 0% 100%);
          clip-path: polygon(0 28%, 100% 0, 100% 100%, 0% 100%);
}

@media only screen and (max-width: 400px) {
  .about {
    padding-top: 16vh;
  }
}

.footer {
  padding: 1rem;
  padding-left: 2rem;
  width: 100%;
  color: #fff;
  text-align: left;
  margin: auto;
  background-color: #000;
}

.scrollTop {
  position: fixed;
  width: 100px;
  bottom: 20px;
  right: 0px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeInArrow 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
  color: grey;
}

.scrollTop:hover {
  opacity: 1;
}

@keyframes fadeInArrow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.portfolioFS {
  padding: 14vh;
  width: 100%;
  color: #fff;
  text-align: center;
  margin: auto;
  background-color: #000;
  text-align: center;
  padding-bottom: 8%;
  padding-top: 240px;
  -webkit-clip-path: polygon(0 20%, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(0 20%, 100% 0, 100% 100%, 0 100%);
}
.portfolioD {
  padding: 14vh;
  width: 100%;
  color: #fff;
  text-align: center;
  margin: auto;
  background-color: #000;
  text-align: center;
  padding-bottom: 12%;
  padding-top: 75px;
  -webkit-clip-path: polygon(0 0, 0 100%, 50% 94%, 100% 100%, 100% 0);
          clip-path: polygon(0 0, 0 100%, 50% 94%, 100% 100%, 100% 0);
}
.carousel-description {
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 0);
  text-align: center;
  background-color: #000000d9;
  padding: 25px;
  width: 100%;
}
.carousel-description a {
  color: #fff;
  text-decoration: none;
}
.carousel-description a:hover {
  color: lightgrey;
  text-decoration: none;
}
a.smallAnchor {
  font-size: .6em;
}
#portfolioFS .css-1fzpoyk,
#portfolioD .css-1fzpoyk {
  position: absolute;
  height: 90%;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
}

#portfolioFS .css-1qzevvg,
#portfolioD .css-1qzevvg {
  position: relative;
  display: flex;
  height: 40px;
  margin: 1rem auto 0px;
  width: 20%;
  justify-content: space-between;
  top: -170px;
  left: 0%;
  transform: translate(-50%, 0);
}

@media only screen and (max-width: 1100px) {
  #portfolioFS .css-1fzpoyk,
  #portfolioD .css-1fzpoyk {
    position: absolute;
    height: 60%;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: 50% 50%;
  }
  .carousel-description {
    font-size: 2vh;
  }
}

@media only screen and (max-width: 790px) {
  #portfolioFS .css-1fzpoyk,
  #portfolioD .css-1fzpoyk {
    position: absolute;
    height: 52%;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: 50% 50%;
  }
  .carousel-description {
    font-size: 2vh;
  }
}

@media only screen and (max-width: 600px) {
  #portfolioFS .css-1fzpoyk,
  #portfolioD .css-1fzpoyk {
    position: absolute;
    height: 45%;
    top: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: 50% 50%;
  }
  .carousel-description {
    font-size: 1.5vh;
  }
}

@media only screen and (max-width: 500px) {
  #portfolioFS .css-1qzevvg,
  #portfolioD .css-1qzevvg {
    position: relative;
    display: flex;
    height: 40px;
    margin: 1rem auto 0px;
    width: 20%;
    justify-content: space-between;
    top: -170px;
    left: 0%;
    transform: translate(-100%, 0);
  }
}

@media only screen and (max-width: 400px) {
  #portfolioFS .css-1fzpoyk,
  #portfolioD .css-1fzpoyk {
    position: absolute;
    height: 30%;
    top: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: 50% 50%;
  }
  .carousel-description {
    font-size: 1.5vh;
  }
}

@media only screen and (max-width: 300px) {
  #portfolioFS .css-1qzevvg,
  #portfolioD .css-1qzevvg {
    position: relative;
    display: flex;
    height: 40px;
    margin: 1rem auto 0px;
    width: 20%;
    justify-content: space-between;
    top: -170px;
    left: 0%;
    transform: translate(-200%, 0);
  }
}

.skills {
  padding: 2rem;
  width: 100%;
  color: #444;
  text-align: center;
  margin: auto;
  padding-top: 5%;
  padding-bottom: 5%;
  background-image: linear-gradient(to bottom right, white, #abb0b5);
}
.uniqueCounters {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.counterColumn {
  width: 30%;
}

@keyframes backgroundColorPalette {
  0% {
    background: #fff;
  }
  25% {
    background: #abb0b5;
  }
  50% {
    background: #fff;
  }
  75% {
    background: #abb0b5;
  }
  100% {
    background: #fff;
  }
}

@media only screen and (max-width: 600px) {
  .counterColumn h3 {
    font-size: 5vw;
  }
}

@media only screen and (max-width: 400px) {
  .skillbar-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    font-weight: bold;
    font-size: 11px;
    color: #fff;
    background: #d35400;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    height: inherit;
  }
}

@media only screen and (max-width: 350px) {
  .skillbar-percent {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 11px;
    line-height: inherit;
    color: #fff;
    color: rgba(0, 0, 0, 0.4);
    display: none;
  }
  .skillbar-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 90px;
    font-weight: bold;
    font-size: 13px;
    color: #fff;
    background: #d35400;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    height: inherit;
  }
}

.education {
  padding: 2vh;
  width: 100%;
  color: #444;
  margin: auto;
  text-align: center;
  padding-bottom: 10%;
  padding-top: 10vh;
  background-image: linear-gradient(to bottom left, white, #abb0b5);
}
.carousel-description {
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 0);
  text-align: center;
  background-color: #000000a1;
  padding: 25px;
  width: 100%;
  color: #fff;
}
.carousel-description a {
  color: #fff;
  text-decoration: none;
}
.carousel-description a:hover {
  color: lightgrey;
  text-decoration: none;
}

#education .css-1fzpoyk {
  position: absolute;
  height: 85%;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
}

#education .css-1qzevvg {
  position: relative;
  display: flex;
  height: 40px;
  margin: 1rem auto 0px;
  width: 20%;
  justify-content: space-between;
  top: -170px;
  left: 6%;
  transform: translate(-50%, 0);
}

@media only screen and (max-width: 1100px) {
  #education .css-1fzpoyk {
    position: absolute;
    height: 72%;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: 50% 50%;
  }
  .carousel-description {
    font-size: 2vh;
  }
}

@media only screen and (max-width: 790px) {
  #education .css-1fzpoyk {
    position: absolute;
    height: 52%;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: 50% 50%;
  }
  .carousel-description {
    font-size: 2vh;
  }
}

@media only screen and (max-width: 600px) {
  #education .css-1fzpoyk {
    position: absolute;
    height: 45%;
    top: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: 50% 50%;
  }
  .carousel-description {
    font-size: 1.5vh;
  }
}

@media only screen and (max-width: 400px) {
  #education .css-1fzpoyk {
    position: absolute;
    height: 28%;
    top: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: 50% 50%;
  }
}

.testimonials {
  padding: 2rem;
  width: 100%;
  color: #444;
  text-align: justify;
  margin: auto;
  padding-top: 5%;
  padding-bottom: 7%;
  text-align: center;
  background-image: linear-gradient(white, #abb0b5);
}
.uniqueTestimonials {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.testimonialsColumn {
  width: 30%;
}
.star {
  width: 40px;
  align-items: center;
  height: 20px;
  justify-content: center;
  color: grey;
}

@media only screen and (max-width: 400px) {
  .testimonialsColumn h3 {
    font-size: 4vw;
  }
  .testimonialsColumn p {
    font-size: 3vw;
  }
}

.container-banner {
  position: relative;
  min-height: 300px;
  max-height: 800px;
  overflow: hidden;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 76%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, 100% 76%, 0% 100%);
  text-align: center;
}
.Video {
  height: 100%;
}
.Content {
  position: absolute;
  top: 0;
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.SubContent {
  text-align: center;
  padding: 10px;
}
.SubContent p {
  color: #eeeeeead;
  font-size: 1.3rem;
}
.SubContent button {
  font-weight: bold;
  margin-top: 30px;
  color: #f1f1f1;
  border: #f1f1f1 1px solid;
  padding: 10px 5px;
  background-color: hsla(0, 0%, 0%, 0);
  font-size: 1.25rem;
  border-radius: 5px;
  transition: background-color 0.5s;
}
.SubContent button:hover {
  background-color: #f1f1f1;
  color: #000000c0;
}
.SubContent img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  border: #f1f1f1 1px solid;
}
.banner-content {
  position: absolute;
  left: 50%;
  top: 25vh;
  transform: translate(-50%, 0);
  text-align: center;
  color: #fff;
  width: 90%;
}
h1.my-name {
  font-size: 5vw;
}
.banner-content h4 {
  font-size: 2.5vw;
}
.large {
  font-size: 10vw;
}

@media only screen and (max-width: 700px) {
  .banner-content {
    position: absolute;
    left: 50%;
    top: 36vw;
    transform: translate(-50%, 0);
    text-align: center;
    color: #fff;
    width: 90%;
  }
  h1.large {
    font-size: 18vw;
  }
  h1.my-name {
    font-size: 8vw;
  }
  .banner-content h4 {
    font-size: 4vw;
  }
}

@media only screen and (max-width: 600px) {
  .banner-content {
    position: absolute;
    left: 50%;
    top: 36vw;
    transform: translate(-50%, 0);
    text-align: center;
    color: #fff;
    width: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .banner-content {
    position: absolute;
    left: 50%;
    top: 60vw;
    transform: translate(-50%, 0);
    text-align: center;
    color: #fff;
    width: 90%;
  }
}

@media only screen and (max-width: 300px) {
  .banner-content {
    position: absolute;
    left: 50%;
    top: 60vw;
    transform: translate(-50%, 0);
    text-align: center;
    color: #fff;
    width: 90%;
  }
}

.anim-text {
  text-align: center;
  line-height: 11rem;
}
@media only screen and (max-width: 400px) {
  .anim-text {
    text-align: center;
    line-height: 6rem;
  }
}

.contact {
  padding: 2rem;
  width: 100%;
  color: #fff;
  text-align: center;
  margin: auto;
  background-color: #000;
  padding-top: 5%;
  padding-bottom: 5%;
}
.uniqueContact {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 5%;
}
.contactColumn {
  width: 48%;
}
.contactColumn button {
  width: 100%;
  padding: 14px;
  transition: background-color 0.5s;
}
.gitLinks {
  padding-top: 7%;
}
.socialLink {
  width: 80px;
  margin: 4px;
  transition: width 0.2s;
}
.socialLink:hover {
  width: 75px;
  opacity: 0.7;
}

