.mynavbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2.5rem;
  position: absolute;
  z-index: 1;
  width: 100%;
  color: #fff;
}

.mynavbar-sticky {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 1px 1px 1px #222;
  animation: moveDown 0.5s ease-in-out;
  color: #444;
  z-index: 50;
  opacity: 0.9;
}

.navbar--logo-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar--logo {
  width: 10rem;
  height: 10rem;
  margin-right: 0.5rem;
  animation: rotate 0.7s ease-in-out 0.5s;
}

.navbar--logo-sticky {
  width: 5rem;
  height: 5rem;
  margin-right: 0.5rem;
  animation: rotate 0.7s ease-in-out 0.5s;
}

.navbar--link {
  display: flex;
  list-style: none;
}
.navbar--link-item a {
  color: #fff;
  text-decoration: none;
}
.navbar--link-item a::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #fff;
  transition: width 0.3s;
}
.navbar--link-item a:hover {
  color: lightgrey;
  text-decoration: none;
}
.navbar--link-item a:hover::after {
  width: 100%;
  /* transition: width .3s; */
}

.navbar--link-item-sticky a {
  color: #000;
}
.navbar--link-item-sticky a:hover {
  color: lightgrey;
  text-decoration: none;
}
.navbar--link-item {
  margin: 0.4rem 1rem 0 0;
  padding: 0 0.3rem;
  cursor: pointer;
  font-size: 1.5vw;
  text-transform: capitalize;
}

.navbar--link-item-sticky {
  margin: 0.4rem 1rem 0 0;
  padding: 0 0.3rem;
  cursor: pointer;
  font-size: 1rem;
  text-transform: capitalize;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0rem);
  }
}

@media only screen and (max-width: 1100px) {
  .navbar--link-item {
    margin: 0.4rem 1rem 0 0;
    padding: 0 0.3rem;
    cursor: pointer;
    font-size: 1.9vw;
    text-transform: capitalize;
  }
}

@media only screen and (max-width: 700px) {
  .mynavbar--logo-holder {
    margin: 0 auto;
  }
  .navbar--link {
    margin: 0 auto;
  }
  .navbar--link-item {
    margin: 0.4rem 1rem 0 0;
    padding: 0 0.3rem;
    cursor: pointer;
    font-size: 2.5vw;
    text-transform: capitalize;
  }
}
@media only screen and (max-width: 500px) {
  .navbar--link-item {
    margin: 0.4rem 1rem 0 0;
    padding: 0 0.3rem;
    cursor: pointer;
    font-size: 4vw;
    text-transform: capitalize;
  }
}

@media only screen and (max-width: 350px) {
  .navbar--link-item {
    margin: 0.4rem 1rem 0 0;
    padding: 0 0.3rem;
    cursor: pointer;
    font-size: 3vw;
    text-transform: capitalize;
  }
  .navbar--link-item-sticky {
    margin: 0.4rem 1rem 0 0;
    padding: 0 0.3rem;
    cursor: pointer;
    font-size: 3vw;
    text-transform: capitalize;
  }
}
