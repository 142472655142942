.education {
  padding: 2vh;
  width: 100%;
  color: #444;
  margin: auto;
  text-align: center;
  padding-bottom: 10%;
  padding-top: 10vh;
  background-image: linear-gradient(to bottom left, white, #abb0b5);
}
.carousel-description {
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 0);
  text-align: center;
  background-color: #000000a1;
  padding: 25px;
  width: 100%;
  color: #fff;
}
.carousel-description a {
  color: #fff;
  text-decoration: none;
}
.carousel-description a:hover {
  color: lightgrey;
  text-decoration: none;
}

#education .css-1fzpoyk {
  position: absolute;
  height: 85%;
  top: 50%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  transform-origin: 50% 50%;
}

#education .css-1qzevvg {
  position: relative;
  display: flex;
  height: 40px;
  margin: 1rem auto 0px;
  width: 20%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  top: -170px;
  left: 6%;
  transform: translate(-50%, 0);
}

@media only screen and (max-width: 1100px) {
  #education .css-1fzpoyk {
    position: absolute;
    height: 72%;
    top: 50%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    transform-origin: 50% 50%;
  }
  .carousel-description {
    font-size: 2vh;
  }
}

@media only screen and (max-width: 790px) {
  #education .css-1fzpoyk {
    position: absolute;
    height: 52%;
    top: 50%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    transform-origin: 50% 50%;
  }
  .carousel-description {
    font-size: 2vh;
  }
}

@media only screen and (max-width: 600px) {
  #education .css-1fzpoyk {
    position: absolute;
    height: 45%;
    top: 20%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    transform-origin: 50% 50%;
  }
  .carousel-description {
    font-size: 1.5vh;
  }
}

@media only screen and (max-width: 400px) {
  #education .css-1fzpoyk {
    position: absolute;
    height: 28%;
    top: 20%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    transform-origin: 50% 50%;
  }
}
