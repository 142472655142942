.testimonials {
  padding: 2rem;
  width: 100%;
  color: #444;
  text-align: justify;
  margin: auto;
  padding-top: 5%;
  padding-bottom: 7%;
  text-align: center;
  background-image: linear-gradient(white, #abb0b5);
}
.uniqueTestimonials {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.testimonialsColumn {
  width: 30%;
}
.star {
  width: 40px;
  align-items: center;
  height: 20px;
  justify-content: center;
  color: grey;
}

@media only screen and (max-width: 400px) {
  .testimonialsColumn h3 {
    font-size: 4vw;
  }
  .testimonialsColumn p {
    font-size: 3vw;
  }
}
