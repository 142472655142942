.container-banner {
  position: relative;
  min-height: 300px;
  max-height: 800px;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 76%, 0% 100%);
  text-align: center;
}
.Video {
  height: 100%;
}
.Content {
  position: absolute;
  top: 0;
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.SubContent {
  text-align: center;
  padding: 10px;
}
.SubContent p {
  color: #eeeeeead;
  font-size: 1.3rem;
}
.SubContent button {
  font-weight: bold;
  margin-top: 30px;
  color: #f1f1f1;
  border: #f1f1f1 1px solid;
  padding: 10px 5px;
  background-color: hsla(0, 0%, 0%, 0);
  font-size: 1.25rem;
  border-radius: 5px;
  transition: background-color 0.5s;
}
.SubContent button:hover {
  background-color: #f1f1f1;
  color: #000000c0;
}
.SubContent img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  border: #f1f1f1 1px solid;
}
.banner-content {
  position: absolute;
  left: 50%;
  top: 25vh;
  transform: translate(-50%, 0);
  text-align: center;
  color: #fff;
  width: 90%;
}
h1.my-name {
  font-size: 5vw;
}
.banner-content h4 {
  font-size: 2.5vw;
}
.large {
  font-size: 10vw;
}

@media only screen and (max-width: 700px) {
  .banner-content {
    position: absolute;
    left: 50%;
    top: 36vw;
    transform: translate(-50%, 0);
    text-align: center;
    color: #fff;
    width: 90%;
  }
  h1.large {
    font-size: 18vw;
  }
  h1.my-name {
    font-size: 8vw;
  }
  .banner-content h4 {
    font-size: 4vw;
  }
}

@media only screen and (max-width: 600px) {
  .banner-content {
    position: absolute;
    left: 50%;
    top: 36vw;
    transform: translate(-50%, 0);
    text-align: center;
    color: #fff;
    width: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .banner-content {
    position: absolute;
    left: 50%;
    top: 60vw;
    transform: translate(-50%, 0);
    text-align: center;
    color: #fff;
    width: 90%;
  }
}

@media only screen and (max-width: 300px) {
  .banner-content {
    position: absolute;
    left: 50%;
    top: 60vw;
    transform: translate(-50%, 0);
    text-align: center;
    color: #fff;
    width: 90%;
  }
}
